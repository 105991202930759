.proj-h1 {
  color: white;
  text-align: center;
}

.projects-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-around; 
  gap: 20px; 
  padding: 20px;
}