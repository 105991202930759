.footer {
  background: #17062e;
  background: linear-gradient(135deg, rgba(42, 42, 42, 0.9) 0%, rgba(60, 60, 60, 0.5) 100%);
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  backdrop-filter: blur(10px); 
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  flex-wrap: wrap;
  gap: 35px; 
  max-width: 1200px; 
  margin: 0 auto; 
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  justify-content: center;
  left: 50%;
}

.footer-logo img {
  width: 100px;
  margin-bottom: 10px; 
}

.footer-contact {
  text-align: right;
  flex: 1;
}

.footer-contact h4 {
  margin-bottom: 10px;
}

.footer-contact p {
  margin: 5px 0; 
}

.footer-social {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex: 1;
}

.footer-social a {
  color: #fff;
  margin: 0 10px;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 14px;
  padding-top: 10px;
  border-top: 1px solid #444; 
}

.footer p {
  margin: 5px 0; 
}
