.achievements-page {
    margin: 50px 0px;
}

.achievement-main {
    text-align: center;
}

.achievement-container {
    margin-top: 80px;
  }
  
  .achievement-title {
    color: white;
    font-weight: 900;
    font-size: 30px;
  }
  
  @media (min-width: 480px) {
    .achievement-title {
      font-size: 40px;
    }
  }
  
  @media (min-width: 640px) {
    .achievement-title {
      font-size: 50px;
    }
  }
  
  @media (min-width: 768px) {
    .achievement-title {
      font-size: 60px;
    }
  }
  
  .achievement-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .achievement-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .achievement-icon {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
  
  .achievement-card-title {
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  
  .achievement-card-event {
    color: #aaa; /* Adjust this color to your secondary color */
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  
  .achievement-card-points {
    margin-top: 20px;
    list-style-type: disc;
    margin-left: 20px;
    gap: 10px;
  }
  
  .achievement-card-point {
    color: #f0f0f0; /* Adjust this color to your white-100 color */
    font-size: 14px;
    padding-left: 5px;
    letter-spacing: 0.05em;
  }
  