.console {
    margin-top: -15px;
    width: 70vw;
    height: 78vh;
    /* min-height: 25rem; */
    background-color: transparent;
    /* background-color: black; */
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Kode Mono" !important;
    font-weight: 400 !important;
    letter-spacing: 1px;


    /*position: relative;*/
    /*mix-blend-mode: color-dodge;*/
}

.console-header {
    height: 18px !important;
    background-color: #070212;
    display: flex;
    margin: 0px -15px;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    /*padding-left: 10px;*/
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    padding: 0.5rem 0.7rem;
    /*background: white;*/
    /* mix-blend-mode: screen; */
}

.console-header div {
    height: 10px;
    width: 10px;
    border-radius: 6px;
    margin: 9px 6px;

}

.red-dot {
    background-color: red;
}

.yellow-dot {
    background-color: #FFBE00;
}

.green-dot {
    background-color: #00DB47;
}


.console-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 2rem;
    /*margin-top: 20px;*/

}

.console-text {
    font-size: 0.95rem;
    margin-left: 1.25rem;
    /* line-height: 20px; */
    /* letter-spacing: 1px; */
}

.input-div {
    color: #08F600;
    font-family: "Kode Mono" !important;
    font-weight: 700;
    margin-left: 1rem;
}

.input-div p {
    /* line-height: 30px; */
}


.console-input {
    margin-left: 5px;
    background-color: transparent;
    border: none;
    font-size: 17px;
    color: #08F600;
    /*font-weight: 400;*/
    width: 300px;
    caret-color: transparent;
    font-family: "Kode Mono" !important;
    transform: translateY(1px);
    /*font-weight: 700;*/
}

.console-input:focus {
    border: none;
    outline: none;
}

.console-arrow {
    font-family: "Kode Mono" !important;
    font-weight: 700;
    font-size: 17px;
    transform: translateY(-2px);
    display: inline-block;
    color: #F45AED;

}

.input-cursor {
    display: inline-block;
    height: 19px;
    width: 10px;
    background-color: #08F600;
    transform: translate(-250px, 1.3px);
    animation: blinking 1s infinite;
    /* transform: translateX(-20px); */
}

@keyframes blinking {
    0% {
        background-color: transparent;
    }
    47% {
        color: #08F600;
    }
    62% {
        color: transparent;
    }
    97% {
        color: transparent;
    }
    100% {
        color: #08F600;
    }
}

.console-img{
    margin-left: 1rem;
    margin-top: 5px;
    height: 200px;
}


.socials{
    display: flex;
    gap: 2rem;
    flex-direction: row-reverse;
    margin-right: 3rem;
    mix-blend-mode: exclusion;
    cursor: pointer;
    /* justify-content: center; */
    align-items: center;
    padding: 15px 0px;
}

.body-end {
    width: 100%;
    height: 0.1px; 
    background-color: rgb(110, 109, 109); 
    margin-bottom: 10px;
    border: none; 
}

.socials img:hover{
    opacity: 0.8;
    transform: scale(1.1);
}